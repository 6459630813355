@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Lusitana);
.cls-1 {
  fill: #fff;
  fill-rule: evenodd; }

.cls-100 {
  fill: #fff;
  font-weight: 300;
  font-style: italic;
  fill-rule: evenodd; }

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 2.188em;
  padding-top: 2.188em;
  border-top: 5px solid #0077bd; }

.transparentBg {
  background: transparent; }

.colorBg {
  background: #192d40; }

button.menu-toggle {
  opacity: 0.6;
  background-color: transparent;
  position: absolute;
  z-index: 2;
  top: 4em;
  left: 88.5%;
  border: none;
  width: 36px;
  height: 30px;
  outline: none;
  transition: opacity 0.2s ease-out; }
  @media (max-width: 576px) {
    button.menu-toggle {
      left: 80%; } }
  button.menu-toggle:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    right: auto;
    width: 100%;
    background: linear-gradient(to bottom, #0077bd, #0077bd 20%, transparent 20%, transparent 40%, #0077bd 40%, #0077bd 60%, transparent 60%, transparent 80%, #0077bd 80%, #0077bd 100%);
    transition: opacity 0.2s ease-out, width 0.2s 0.2s ease-out; }
  button.menu-toggle:after {
    opacity: 0;
    content: '×';
    color: white;
    position: absolute;
    top: 16px;
    left: -4px;
    font-family: Arial, sans-serif;
    font-size: 76px;
    line-height: 0;
    transition: opacity 0.4s ease-out; }
  button.menu-toggle:active {
    transform: translateY(2px); }
  button.menu-toggle:hover {
    opacity: 1; }
  .open button.menu-toggle {
    opacity: 1;
    position: fixed; }
    .open button.menu-toggle:before {
      opacity: 0;
      width: 0; }
    .open button.menu-toggle:after {
      opacity: 1;
      transform: translate3d(0, 0, 0) rotate(360deg);
      transition: transform 0.4s 1s ease-out, opacity 0.4s 1s ease-out; }

nav {
  z-index: 1;
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  overflow: hidden; }
  nav:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: rgba(0, 87, 138, 0.98);
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
    transform: scale(0.04), translateY(9999px);
    overflow: hidden; }
  .open nav {
    top: 0; }
    .open nav:before {
      animation: menu-animation 0.8s ease-out forwards; }

ul.menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  backface-visibility: hidden;
  perspective: 1000;
  color: white;
  list-style: none;
  margin: 0;
  padding: 0; }
  ul.menu li {
    opacity: 0;
    text-align: center;
    transform: translate3d(0, 36px, 0);
    cursor: pointer;
    padding: 6px 20px;
    font-size: 48px;
    font-weight: 400; }
    @media (max-width: 576px) {
      ul.menu li {
        font-size: 1.5em; } }
    ul.menu li a {
      color: white;
      text-decoration: none; }
      ul.menu li a:hover {
        color: #2b8ec8; }
    ul.menu li:after {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      color: #0077bd;
      overflow: hidden;
      transform: translate(-24px, 6px);
      transition: transform 0.1s ease-out, opacity 0.1s ease-out; }
    ul.menu li:hover:before {
      left: 0;
      right: auto;
      width: 100%; }
    ul.menu li:hover:after {
      opacity: 1;
      padding: 0 20px;
      transform: translate(0px, 6px);
      transition: transform 0.2s 0.14s ease-out, opacity 0.2s 0.14s ease-out; }
    .open ul.menu li {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: transform 0.2s ease-out, opacity 0.2s ease-out; }
      .open ul.menu li:nth-child(1) {
        transition-delay: 0.75s; }
      .open ul.menu li:nth-child(2) {
        transition-delay: 0.85s; }
      .open ul.menu li:nth-child(3) {
        transition-delay: 0.95s; }
      .open ul.menu li:nth-child(4) {
        transition-delay: 1.05s; }

/*2 - in Garbage.scss goes here*/
@keyframes menu-animation {
  0% {
    opacity: 0;
    transform: scale(0.04) translateY(300%); }
  40% {
    transform: scale(0.04) translateY(0);
    transition: ease-out; }
  40% {
    transform: scale(0.04) translateY(0); }
  60% {
    opacity: 1;
    transform: scale(0.02) translateY(0px); }
  61% {
    transform: scale(0.04); }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%; }
  100% {
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0; } }
