@import "../../../styles/media";
$sky-blue: #2b8ec8;
$dark-blue: #192d40;//#375571;
$white: #fff;
$footer-text-color: #FFF;
$footer-text: 1.25rem; //20px
$bottom-text: 0.8rem; //12px
$bottom-text-small: 1.0rem;
$footer-padding-lg: 3rem 6.250rem;

@mixin box($bg-color) {
  background: $bg-color;
  padding: $footer-padding-lg;
  display: flex;
  justify-content: space-between;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      text-decoration: none;
      color: $white;
    }

  }

}

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  color: $footer-text-color;
  font-size: 1.25rem;



  &-top {
    width: 100%;
    background: $sky-blue;

    .wrapper {
      @include box($sky-blue);
      @include lg {
        padding: 2rem 3rem 0 3rem;
      }
      @include sm {
        @include box($sky-blue);
        justify-content: left;
        flex-direction: column;
        padding: 3rem;
      }

      ul {
        list-style: none;
        margin: {
          top: 0;
          bottom: 0;
          left: 1rem;
          right: 0;
        }
        @include sm {
          margin-left: 0;
        }
        padding: 0;

        &.social{
          display: flex;
          margin-left: 0;
          padding-left: 0;

          li {
            margin-right: 2.500em;
          }
        }

        li {
          margin-bottom: 2.5rem;

          @include sm {
            margin-bottom: 1.5rem;
          }
        }
      }
    }


  }

  &-bottom {
    background: $dark-blue;
    width: 100%;
    font-size: $bottom-text;
    text-transform: uppercase;

    @include sm {
      font-size: $bottom-text-small;
    }

    .wrapper {
      @include box($dark-blue);
      @include lg {
        padding: 3rem;
      }
      @include sm {
        justify-content: normal;
        flex-direction: column;
        padding: 2rem 3rem;
      }
      ul {
        margin: 0;

        &.privacy{
          width: 18rem;
        }

        li {
          display: inline;
          margin-right: 6.250rem;

          @include sm {
            display: block;
            margin-right: 0;
           margin-bottom: 10px;
          }

          a {
            text-decoration: none;
            color: $white;
          }
        }
      }
    }
  }

  &-content {
    h1 {
      margin-bottom: 2.0rem;
      @include lg {
        font-size: 1.8em;
      }
      @include sm {
        margin-bottom: 1rem;
        font-size: 1.8em;
      }
    }

    p {
      margin-bottom: 6.0rem;

      @include lg {
        margin-bottom: 3.0rem;
      }
      @include sm {
        margin-bottom: 3rem;
      }
    }

  }

  &-contact {
    width: 18rem;
    @include lg {
      margin-left: 1rem;
    }
    @include sm {
      width: 100%;
      margin-left: 0rem;
    }

    h1 {
      margin-bottom: 2.0rem;

      @include lg {
        font-size: 1.8em;
      }
      @include sm {
        margin-bottom: 1rem;
        font-size: 1.8em;
      }
    }
  }

}

