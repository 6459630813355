@import "../../styles/media";

$mission-bg: #2b8ec8;
$value-txt: #2c4c68;
.about {
  font-size: 1.25rem;
  line-height: 50px;
  background: #f1f5f8;
  padding-bottom: 5rem;


  .aboutMission {
    background: $mission-bg;
    color: #fff;

    h1 {
      font-weight: 600;
      margin: 0;
      text-align: center;
      padding: 5.000rem 0 3rem;
      font-size: 3.125em;
      line-height: 50px;

      @include md {
        font-size: 2.5rem;
        padding: 3rem 0;
      }
    }

    p {
      column-count: 2;
      column-rule-style: solid;
      column-gap: 100px;
      column-rule-width: 1px;
      padding-bottom: 9rem;

      .emphasis {
        font-weight: bold;
        display: block;

        @include md {
          text-align: center;
          font-size: larger;
        }
      }

      @include md {
        column-count: 1;
      }
    }
  }

  .separator {
    background: url("../../../src/images/seperator.png") no-repeat;
    width: 351px;
    height: 5px;
    margin: 2em auto;
  }

  .aboutValueDfn {
    display: flex;
    flex-wrap: wrap;

    @include lg {
      flex-direction: column;
    }
    .image-collage {
      flex: 45%;
      position: relative;
      margin-top: -5em;
      margin-bottom: 5rem;

      .row {
        display: flex;
        .col {
          &1 {
            width: 40%;
            padding: 0 10px 0 0;
          }

          &2 {
            width: 60%;
            text-align: center;
            padding: 0 10px 0 0;
          }
        }

        .div {
          &1 {
            margin-top: 25%;
            width: 100%;
            height: 0;
            margin-bottom: 107%;
          }

          &2 {
            width: 100%;
          }

          &3 {
            width: 90%;
            float: right;

            @include lg {
              margin-bottom: 2rem;
            }

            @include sm {
              margin-bottom: 0;
            }
          }
        }

        .pic {
          &1 {
            width: 100%;
          }

          &2 {
            width: 100%;
          }

          &3 {
            width: 100%;
          }
        }
      }
    }

    .value-section {
      flex: 40%;


      p {
        margin: 40% 1.5rem 0rem;

        @include lg {
          text-align: center;
          margin-top: 1rem;
        }
      }
    }
  }


  .aboutValueCtg {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    width: 80%;
    margin: 0 auto;
    padding: 3rem;

    @include md {
      padding: 2rem;
      width: 100%;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: right;
      align-items: center;

      @include sm {
        flex-direction: column;
      }
    }

    li {
      margin: 10px 25px;
      width: 15rem;
    }

    .title {
      display: block;
      text-transform: uppercase;
      color: $value-txt;
      font-weight: bold;
      text-align: right;

      @include sm {
        text-align: center;
      }
    }

    li:nth-child(2) {
      width: 30rem;

      @include sm {
        width: 100%;
      }
    }
  }

  h1.ourValues_sm {
    font-size: 3.125em;
    line-height: 50px;
    color: $mission-bg;
    margin: 2rem 0 0 4.5rem;
    display: none;

    @include md {
      display: block;
      width: 300px;
      margin: 0rem auto 2rem;
    }

    @include sm {
      font-size: 2.5em;
      width: 230px;
      margin: 0rem auto 2rem;
    }

    img {
      margin-top: 0.8rem;
    }
  }

  h1.ourValues_lg {
    font-size: 3rem;
    line-height: 50px;
    color: $mission-bg;
    margin: 1rem auto;
    width: 5em;

    @include sm {

      font-size: 2.3rem;
      margin: 0;
      width: 100%;
      line-height: 47px;
    }
    img {
      margin-top: 0.3rem;

      @include sm{
        margin-top: 0;
        width: 75%;
      }
    }
  }
}
