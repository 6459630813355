@import "../../../styles/media";

$border-style: linear-gradient(to right, #fff 80px, rgba(0,0,0,0) 5%);
.defaultTpl {
  display: flex;
  flex-direction: column;
  background: #192d40;
  padding-bottom: 5.625rem;

  h1 {
    color: #6d8094;
    font-size: 250px;
    line-height: 190px;
    margin-bottom: 4rem;

    @include rwd(1150) {
      font-size: 13.0rem;
    }

    @include rwd(880) {
      font-size: 9.0rem;
    }
    @include lg {
      font-size: 9.0rem;
      line-height: 0.7em;
      margin-top: 2rem;
    }

    @include sm {
      font-size: 5rem;
      line-height: 60px;
      margin-top: 2.0rem;
      //text-align: center;
    }

    @include rwd(500) {
      font-size: 4rem;
    }

  }
  p {
    color: #fff;
    font-size: 30px;
    margin: 0 0 0 10rem;
    padding: 2rem 3rem 0;
    line-height: 40px;
    border: 1px solid #fff;
    border-radius: 20px 0 0 0;
    border-right: none;
    border-bottom: none;
    border-image: $border-style;
    border-image-slice: 1 1 1 1;
    @include rwd(1150) {
      margin: 0 0 0 3rem;
    }

    @include lg {
      margin: 0 0 0 2rem;
      padding: 2rem 2rem 0.0rem;
    }
    @include sm{
      margin: 0;
      padding: 1em;
      font-size: 23px;
    }
  }
}


