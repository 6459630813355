@import url(https://fonts.googleapis.com/css?family=Lusitana);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600);
#container {
  background: #ffffff; }

.jumbotron {
  background: #cacaca;
  background: #1a3b4a; }

div.jumbotronBG {
  background-repeat: no-repeat;
  padding-top: 150px;
  background-size: contain;
  background-position: right top;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 1129px) {
    div.jumbotronBG {
      background-position: left top;
      background-size: cover;
      padding-top: 0; } }
  @media (max-width: 992px) {
    div.jumbotronBG {
      height: 712px; } }

.jumbotronContent {
  padding-top: 8rem;
  padding-bottom: 8rem;
  display: flex;
  align-content: space-between; }
  @media (max-width: 768px) {
    .jumbotronContent {
      padding-top: 15rem;
      padding-bottom: 8rem;
      flex-direction: column-reverse;
      justify-content: center;
      height: inherit; } }
  @media (max-width: 576px) {
    .jumbotronContent {
      padding-top: 0;
      padding-bottom: 0;
      flex-direction: column-reverse;
      justify-content: center; } }
  @media (max-width: 1129px) {
    .jumbotronContent {
      padding-top: 8rem;
      padding-bottom: 2rem; } }

@media (max-width: 768px) {
  div.text1 {
    margin-top: 2rem; } }

h1.text1 {
  font-size: 4.875rem;
  font-weight: bold;
  color: white; }
  @media (max-width: 1200px) {
    h1.text1 {
      font-size: 3.5rem; } }
  @media (max-width: 1129px) {
    h1.text1 {
      font-size: 3.0em; } }
  @media (max-width: 992px) {
    h1.text1 {
      font-size: 2.4em; } }
  @media (max-width: 768px) {
    h1.text1 {
      font-size: 2.0rem; } }

h1.text1 mark {
  background: #192d40;
  color: #fff;
  padding: 0 15px 10px 0rem;
  box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.39); }

span.space {
  display: inline-block;
  width: 4rem;
  height: 3rem; }

span.emphasis-text1 {
  color: #2b8ec8; }

.text2 p {
  font-size: 30px;
  font-weight: bold;
  color: white;
  margin-left: 1.0em; }
  @media (max-width: 1129px) {
    .text2 p {
      font-size: 1.8em; } }
  @media (max-width: 992px) {
    .text2 p {
      font-size: 1.4em; } }
  @media (max-width: 768px) {
    .text2 p {
      font-size: 1.5rem;
      margin-left: 0; } }

.text2 span {
  display: inline-block;
  text-decoration: underline;
  -webkit-text-decoration-color: #2b8ec8;
          text-decoration-color: #2b8ec8;
  clear: right; }

a.contact-btn {
  display: block;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.8rem;
  padding: 2rem 0;
  float: right;
  position: relative;
  top: 0px;
  margin-right: 5rem;
  background: #2b8ec8;
  min-width: 220px;
  text-align: center;
  box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.39); }
  @media (max-width: 992px) {
    a.contact-btn {
      font-size: 1.4em;
      padding: 1.0em 0;
      min-width: 180px; } }
  @media (max-width: 768px) {
    a.contact-btn {
      font-size: 1.5rem;
      min-width: 85px;
      padding: 1.5rem 0.5rem;
      width: 185px; } }

.mission {
  background: #2b8ec8;
  color: #fff;
  font-size: 1.25rem;
  padding: 2.0em 0 0 0;
  max-height: 700px;
  border-top: 1px solid #fff; }
  .mission div.wrapper {
    display: flex; }
    .mission div.wrapper .left {
      line-height: 40px; }
      .mission div.wrapper .left h1 {
        font-size: 3.125em;
        line-height: 50px; }
        .mission div.wrapper .left h1 span {
          text-decoration: underline;
          -webkit-text-decoration-color: #375571;
                  text-decoration-color: #375571; }
      .mission div.wrapper .left p {
        margin: 30px 30px 30px 0; }
        .mission div.wrapper .left p .emphasis {
          font-weight: bold; }
      .mission div.wrapper .left p:nth-child(3) {
        margin-top: 20px; }
    .mission div.wrapper .right {
      display: flex;
      flex-direction: column;
      font-size: 1.563em;
      font-weight: bold;
      text-align: center; }
      .mission div.wrapper .right p {
        position: relative;
        top: 7px; }
        .mission div.wrapper .right p .emphasis {
          color: #98c7f3; }
      .mission div.wrapper .right img {
        width: 500px;
        box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.39); }
  @media (max-width: 1120px) {
    .mission {
      max-height: none;
      padding-bottom: 2rem; }
      .mission div.wrapper {
        padding: 0 2rem;
        align-items: center;
        justify-content: center; }
        .mission div.wrapper .left h1 {
          font-size: 2.5em; }
        .mission div.wrapper .right img {
          width: 400px; } }
  @media (max-width: 940px) {
    .mission {
      padding: 2em 0 0 0; }
      .mission div.wrapper {
        padding: 0 2rem;
        flex-direction: column; }
        .mission div.wrapper .left p {
          margin: 30px 30px 0 0; }
        .mission div.wrapper .right {
          position: relative;
          top: 50px;
          font-size: 2.3rem; } }
  @media (max-width: 768px) {
    .mission {
      max-height: none;
      padding: 2.0em 0; }
      .mission div.wrapper .left h1 {
        font-size: 2em;
        line-height: 38px; }
      .mission div.wrapper .right {
        display: none; }
      .mission div.wrapper .left p {
        margin: 30px 0; } }

.services .wrapper {
  padding: 5.0em 0 3.0em; }
  .services .wrapper h1 {
    font-size: 3.125em;
    line-height: 50px;
    color: #2b8ec8;
    text-align: center;
    margin-bottom: 30px; }
  .services .wrapper .accordionWrapperStyle {
    background: transparent;
    border-bottom: 1px solid #2b8ec8;
    padding: 40px 20px; }
  .services .wrapper .accordionWrapperStyleLast {
    background: transparent;
    padding: 40px 20px; }
  .services .wrapper .content {
    display: flex;
    margin: 0 auto;
    align-content: space-between;
    justify-content: center; }
    .services .wrapper .content .left {
      margin: 20px; }
      .services .wrapper .content .left svg.gear_wheels {
        width: 400px; }
    .services .wrapper .content .right {
      width: 450px; }
      .services .wrapper .content .right .labelStyle {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer; }

@media (max-width: 992px) {
  .services .wrapper .content .left svg.gear_wheels {
    width: 350px; }
  .services .right {
    margin-right: 1.5rem; } }

@media (max-width: 768px) {
  .services .wrapper {
    padding: 2.0em 3.0em; }
    .services .wrapper h1 {
      font-size: 2em;
      line-height: 50px;
      color: #2b8ec8;
      text-align: left;
      margin-bottom: 0; }
    .services .wrapper .content {
      display: flex;
      margin: 0;
      flex-direction: column; }
      .services .wrapper .content .left {
        display: none; }
      .services .wrapper .content .right {
        width: 100%; }
        .services .wrapper .content .right .labelStyle {
          font-size: 1.25em;
          text-transform: uppercase;
          font-weight: bold;
          cursor: pointer; }
    .services .wrapper .accordionWrapperStyle {
      background: transparent;
      border-bottom: 1px solid #2b8ec8;
      padding: 40px 0px; }
    .services .wrapper .accordionWrapperStyleLast {
      background: transparent;
      padding: 40px 0px; } }

.servicesContainer {
  background: #f1f5f8;
  padding: 0rem 0 10rem; }
  @media (max-width: 500px) {
    .servicesContainer .wrapper {
      padding: 1rem; } }
  .servicesContainer .columns-span-2 {
    display: flex; }
  .servicesContainer .content {
    border-radius: 5px; }
    .servicesContainer .content .h2 {
      font-size: 50px;
      color: #2b8ec8;
      padding: 0;
      margin: 0; }
      @media (max-width: 768px) {
        .servicesContainer .content .h2 {
          display: none; } }
    .servicesContainer .content .h4 {
      font-size: 24px;
      color: #2b8ec8;
      text-transform: uppercase;
      padding: 0;
      margin: 0.5rem; }
    .servicesContainer .content .right {
      background: #FFFFFF;
      border-top: 3px solid #2b8ec8;
      font-size: 1.25rem;
      line-height: 50px; }
    .servicesContainer .content .tab-list {
      padding: 1rem 0 2rem 0;
      margin: 0; }
    .servicesContainer .content .tab-list-item {
      background: #2b8ec8;
      text-align: center;
      color: white;
      height: 60px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 20px;
      cursor: pointer; }
    .servicesContainer .content .tab-list-active {
      background-color: #c7e3f5;
      color: #4c94be;
      font-weight: bold; }
    .servicesContainer .content .carousel {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 60px 0 20px; }
      .servicesContainer .content .carousel button {
        margin: 0 10px;
        width: 50px;
        height: 60px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        outline: none;
        color: #2b8ec8;
        background: #c7e3f5; }

.defaultTpl {
  display: flex;
  flex-direction: column;
  background: #192d40;
  padding-bottom: 5.625rem; }
  .defaultTpl h1 {
    color: #6d8094;
    font-size: 250px;
    line-height: 190px;
    margin-bottom: 4rem; }
    @media (max-width: 1150px) {
      .defaultTpl h1 {
        font-size: 13.0rem; } }
    @media (max-width: 880px) {
      .defaultTpl h1 {
        font-size: 9.0rem; } }
    @media (max-width: 992px) {
      .defaultTpl h1 {
        font-size: 9.0rem;
        line-height: 0.7em;
        margin-top: 2rem; } }
    @media (max-width: 576px) {
      .defaultTpl h1 {
        font-size: 5rem;
        line-height: 60px;
        margin-top: 2.0rem; } }
    @media (max-width: 500px) {
      .defaultTpl h1 {
        font-size: 4rem; } }
  .defaultTpl p {
    color: #fff;
    font-size: 30px;
    margin: 0 0 0 10rem;
    padding: 2rem 3rem 0;
    line-height: 40px;
    border: 1px solid #fff;
    border-radius: 20px 0 0 0;
    border-right: none;
    border-bottom: none;
    border-image: linear-gradient(to right, #fff 80px, rgba(0, 0, 0, 0) 5%);
    border-image-slice: 1 1 1 1; }
    @media (max-width: 1150px) {
      .defaultTpl p {
        margin: 0 0 0 3rem; } }
    @media (max-width: 992px) {
      .defaultTpl p {
        margin: 0 0 0 2rem;
        padding: 2rem 2rem 0.0rem; } }
    @media (max-width: 576px) {
      .defaultTpl p {
        margin: 0;
        padding: 1em;
        font-size: 23px; } }

.about {
  font-size: 1.25rem;
  line-height: 50px;
  background: #f1f5f8;
  padding-bottom: 5rem; }
  .about .aboutMission {
    background: #2b8ec8;
    color: #fff; }
    .about .aboutMission h1 {
      font-weight: 600;
      margin: 0;
      text-align: center;
      padding: 5.000rem 0 3rem;
      font-size: 3.125em;
      line-height: 50px; }
      @media (max-width: 768px) {
        .about .aboutMission h1 {
          font-size: 2.5rem;
          padding: 3rem 0; } }
    .about .aboutMission p {
      -webkit-column-count: 2;
              column-count: 2;
      -webkit-column-rule-style: solid;
              column-rule-style: solid;
      grid-column-gap: 100px;
      -webkit-column-gap: 100px;
              column-gap: 100px;
      -webkit-column-rule-width: 1px;
              column-rule-width: 1px;
      padding-bottom: 9rem; }
      .about .aboutMission p .emphasis {
        font-weight: bold;
        display: block; }
        @media (max-width: 768px) {
          .about .aboutMission p .emphasis {
            text-align: center;
            font-size: larger; } }
      @media (max-width: 768px) {
        .about .aboutMission p {
          -webkit-column-count: 1;
                  column-count: 1; } }
  .about .separator {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAV8AAAABCAYAAAB5Rq2yAAAAI0lEQVQ4jWM8dOjQfwbyASMROkfNHzV/1PxR80fNRwYMDAwAN1QRR0Uq4IQAAAAASUVORK5CYII=) no-repeat;
    width: 351px;
    height: 5px;
    margin: 2em auto; }
  .about .aboutValueDfn {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 992px) {
      .about .aboutValueDfn {
        flex-direction: column; } }
    .about .aboutValueDfn .image-collage {
      flex: 45% 1;
      position: relative;
      margin-top: -5em;
      margin-bottom: 5rem; }
      .about .aboutValueDfn .image-collage .row {
        display: flex; }
        .about .aboutValueDfn .image-collage .row .col1 {
          width: 40%;
          padding: 0 10px 0 0; }
        .about .aboutValueDfn .image-collage .row .col2 {
          width: 60%;
          text-align: center;
          padding: 0 10px 0 0; }
        .about .aboutValueDfn .image-collage .row .div1 {
          margin-top: 25%;
          width: 100%;
          height: 0;
          margin-bottom: 107%; }
        .about .aboutValueDfn .image-collage .row .div2 {
          width: 100%; }
        .about .aboutValueDfn .image-collage .row .div3 {
          width: 90%;
          float: right; }
          @media (max-width: 992px) {
            .about .aboutValueDfn .image-collage .row .div3 {
              margin-bottom: 2rem; } }
          @media (max-width: 576px) {
            .about .aboutValueDfn .image-collage .row .div3 {
              margin-bottom: 0; } }
        .about .aboutValueDfn .image-collage .row .pic1 {
          width: 100%; }
        .about .aboutValueDfn .image-collage .row .pic2 {
          width: 100%; }
        .about .aboutValueDfn .image-collage .row .pic3 {
          width: 100%; }
    .about .aboutValueDfn .value-section {
      flex: 40% 1; }
      .about .aboutValueDfn .value-section p {
        margin: 40% 1.5rem 0rem; }
        @media (max-width: 992px) {
          .about .aboutValueDfn .value-section p {
            text-align: center;
            margin-top: 1rem; } }
  .about .aboutValueCtg {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    width: 80%;
    margin: 0 auto;
    padding: 3rem; }
    @media (max-width: 768px) {
      .about .aboutValueCtg {
        padding: 2rem;
        width: 100%; } }
    .about .aboutValueCtg ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: right;
      align-items: center; }
      @media (max-width: 576px) {
        .about .aboutValueCtg ul {
          flex-direction: column; } }
    .about .aboutValueCtg li {
      margin: 10px 25px;
      width: 15rem; }
    .about .aboutValueCtg .title {
      display: block;
      text-transform: uppercase;
      color: #2c4c68;
      font-weight: bold;
      text-align: right; }
      @media (max-width: 576px) {
        .about .aboutValueCtg .title {
          text-align: center; } }
    .about .aboutValueCtg li:nth-child(2) {
      width: 30rem; }
      @media (max-width: 576px) {
        .about .aboutValueCtg li:nth-child(2) {
          width: 100%; } }
  .about h1.ourValues_sm {
    font-size: 3.125em;
    line-height: 50px;
    color: #2b8ec8;
    margin: 2rem 0 0 4.5rem;
    display: none; }
    @media (max-width: 768px) {
      .about h1.ourValues_sm {
        display: block;
        width: 300px;
        margin: 0rem auto 2rem; } }
    @media (max-width: 576px) {
      .about h1.ourValues_sm {
        font-size: 2.5em;
        width: 230px;
        margin: 0rem auto 2rem; } }
    .about h1.ourValues_sm img {
      margin-top: 0.8rem; }
  .about h1.ourValues_lg {
    font-size: 3rem;
    line-height: 50px;
    color: #2b8ec8;
    margin: 1rem auto;
    width: 5em; }
    @media (max-width: 576px) {
      .about h1.ourValues_lg {
        font-size: 2.3rem;
        margin: 0;
        width: 100%;
        line-height: 47px; } }
    .about h1.ourValues_lg img {
      margin-top: 0.3rem; }
      @media (max-width: 576px) {
        .about h1.ourValues_lg img {
          margin-top: 0;
          width: 75%; } }

.ContactFormContent {
  background: #f1f5f8;
  padding: 0 0 3rem; }
  @media (max-width: 500px) {
    .ContactFormContent .wrapper {
      padding: 0; } }
  .ContactFormContent h3 {
    margin: 0;
    border-left: 1px solid #2b8ec8;
    color: #2b8ec8;
    font-size: 30px;
    padding: 1.250rem 1.250rem 3.750rem;
    position: relative;
    top: 2.5rem;
    left: 3rem; }
    @media (max-width: 500px) {
      .ContactFormContent h3 {
        left: 2rem;
        top: 2.0rem;
        font-size: 25px; } }
  .ContactFormContent .columns-span-2 {
    display: flex;
    flex-direction: row; }
  .ContactFormContent .equal {
    flex: 1 1; }
  @media (max-width: 992px) {
    .ContactFormContent .left {
      display: none; } }
  .ContactFormContent form {
    padding: 3.125rem;
    display: flex;
    background: #fff;
    flex-direction: column;
    margin-bottom: 4rem; }
    @media (max-width: 1200px) {
      .ContactFormContent form {
        width: 550px; } }
    @media (max-width: 576px) {
      .ContactFormContent form {
        width: 27rem; } }
    @media (max-width: 500px) {
      .ContactFormContent form {
        width: 100%;
        padding: 2rem; } }
    .ContactFormContent form .formGroup {
      display: flex;
      flex-direction: column;
      margin: 1rem 0; }
      .ContactFormContent form .formGroup label {
        text-transform: uppercase;
        font-weight: bold;
        color: #2c4c68;
        margin-bottom: 1.250rem;
        font-size: 22px; }
        @media (max-width: 576px) {
          .ContactFormContent form .formGroup label {
            font-size: 1.2em; } }
      .ContactFormContent form .formGroup input {
        border: none;
        height: 2.188rem;
        border-bottom: 1px solid #2b8ec8;
        margin-bottom: 0.5rem; }
      .ContactFormContent form .formGroup textarea {
        border: none;
        border-bottom: 2px solid #2b8ec8;
        margin-bottom: 0.5rem;
        width: 100%;
        height: 5rem;
        resize: vertical; }
    .ContactFormContent form button {
      border: none;
      background: transparent;
      cursor: pointer;
      font-size: 25px;
      align-self: flex-end;
      color: #2c4c68;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 100; }
      @media (max-width: 576px) {
        .ContactFormContent form button {
          font-size: 1.3em; } }

@charset "UTF-8";
.cls-1 {
  fill: #fff;
  fill-rule: evenodd; }

.cls-100 {
  fill: #fff;
  font-weight: 300;
  font-style: italic;
  fill-rule: evenodd; }

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 2.188em;
  padding-top: 2.188em;
  border-top: 5px solid #0077bd; }

.transparentBg {
  background: transparent; }

.colorBg {
  background: #192d40; }

button.menu-toggle {
  opacity: 0.6;
  background-color: transparent;
  position: absolute;
  z-index: 2;
  top: 4em;
  left: 88.5%;
  border: none;
  width: 36px;
  height: 30px;
  outline: none;
  transition: opacity 0.2s ease-out; }
  @media (max-width: 576px) {
    button.menu-toggle {
      left: 80%; } }
  button.menu-toggle:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    right: auto;
    width: 100%;
    background: linear-gradient(to bottom, #0077bd, #0077bd 20%, transparent 20%, transparent 40%, #0077bd 40%, #0077bd 60%, transparent 60%, transparent 80%, #0077bd 80%, #0077bd 100%);
    transition: opacity 0.2s ease-out, width 0.2s 0.2s ease-out; }
  button.menu-toggle:after {
    opacity: 0;
    content: '\D7';
    color: white;
    position: absolute;
    top: 16px;
    left: -4px;
    font-family: Arial, sans-serif;
    font-size: 76px;
    line-height: 0;
    transition: opacity 0.4s ease-out; }
  button.menu-toggle:active {
    transform: translateY(2px); }
  button.menu-toggle:hover {
    opacity: 1; }
  .open button.menu-toggle {
    opacity: 1;
    position: fixed; }
    .open button.menu-toggle:before {
      opacity: 0;
      width: 0; }
    .open button.menu-toggle:after {
      opacity: 1;
      transform: translate3d(0, 0, 0) rotate(360deg);
      transition: transform 0.4s 1s ease-out, opacity 0.4s 1s ease-out; }

nav {
  z-index: 1;
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow: hidden; }
  nav:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: rgba(0, 87, 138, 0.98);
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
    transform: scale(0.04), translateY(9999px);
    overflow: hidden; }
  .open nav {
    top: 0; }
    .open nav:before {
      animation: menu-animation 0.8s ease-out forwards; }

ul.menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  perspective: 1000;
  color: white;
  list-style: none;
  margin: 0;
  padding: 0; }
  ul.menu li {
    opacity: 0;
    text-align: center;
    transform: translate3d(0, 36px, 0);
    cursor: pointer;
    padding: 6px 20px;
    font-size: 48px;
    font-weight: 400; }
    @media (max-width: 576px) {
      ul.menu li {
        font-size: 1.5em; } }
    ul.menu li a {
      color: white;
      text-decoration: none; }
      ul.menu li a:hover {
        color: #2b8ec8; }
    ul.menu li:after {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      color: #0077bd;
      overflow: hidden;
      transform: translate(-24px, 6px);
      transition: transform 0.1s ease-out, opacity 0.1s ease-out; }
    ul.menu li:hover:before {
      left: 0;
      right: auto;
      width: 100%; }
    ul.menu li:hover:after {
      opacity: 1;
      padding: 0 20px;
      transform: translate(0px, 6px);
      transition: transform 0.2s 0.14s ease-out, opacity 0.2s 0.14s ease-out; }
    .open ul.menu li {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: transform 0.2s ease-out, opacity 0.2s ease-out; }
      .open ul.menu li:nth-child(1) {
        transition-delay: 0.75s; }
      .open ul.menu li:nth-child(2) {
        transition-delay: 0.85s; }
      .open ul.menu li:nth-child(3) {
        transition-delay: 0.95s; }
      .open ul.menu li:nth-child(4) {
        transition-delay: 1.05s; }

/*2 - in Garbage.scss goes here*/
@keyframes menu-animation {
  0% {
    opacity: 0;
    transform: scale(0.04) translateY(300%); }
  40% {
    transform: scale(0.04) translateY(0);
    transition: ease-out; }
  40% {
    transform: scale(0.04) translateY(0); }
  60% {
    opacity: 1;
    transform: scale(0.02) translateY(0px); }
  61% {
    transform: scale(0.04); }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%; }
  100% {
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0; } }

.about {
  font-size: 1.25rem;
  line-height: 50px;
  background: #f1f5f8;
  padding-bottom: 5rem; }
  .about .aboutMission {
    background: #2b8ec8;
    color: #fff; }
    .about .aboutMission h1 {
      font-weight: 600;
      margin: 0;
      text-align: center;
      padding: 5.000rem 0 3rem;
      font-size: 3.125em;
      line-height: 50px; }
      @media (max-width: 768px) {
        .about .aboutMission h1 {
          font-size: 2.5rem;
          padding: 3rem 0; } }
    .about .aboutMission p {
      -webkit-column-count: 2;
              column-count: 2;
      -webkit-column-rule-style: solid;
              column-rule-style: solid;
      grid-column-gap: 100px;
      -webkit-column-gap: 100px;
              column-gap: 100px;
      -webkit-column-rule-width: 1px;
              column-rule-width: 1px;
      padding-bottom: 9rem; }
      .about .aboutMission p .emphasis {
        font-weight: bold;
        display: block; }
        @media (max-width: 768px) {
          .about .aboutMission p .emphasis {
            text-align: center;
            font-size: larger; } }
      @media (max-width: 768px) {
        .about .aboutMission p {
          -webkit-column-count: 1;
                  column-count: 1; } }
  .about .separator {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAV8AAAABCAYAAAB5Rq2yAAAAI0lEQVQ4jWM8dOjQfwbyASMROkfNHzV/1PxR80fNRwYMDAwAN1QRR0Uq4IQAAAAASUVORK5CYII=) no-repeat;
    width: 351px;
    height: 5px;
    margin: 2em auto; }
  .about .aboutValueDfn {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 992px) {
      .about .aboutValueDfn {
        flex-direction: column; } }
    .about .aboutValueDfn .image-collage {
      flex: 45% 1;
      position: relative;
      margin-top: -5em;
      margin-bottom: 5rem; }
      .about .aboutValueDfn .image-collage .row {
        display: flex; }
        .about .aboutValueDfn .image-collage .row .col1 {
          width: 40%;
          padding: 0 10px 0 0; }
        .about .aboutValueDfn .image-collage .row .col2 {
          width: 60%;
          text-align: center;
          padding: 0 10px 0 0; }
        .about .aboutValueDfn .image-collage .row .div1 {
          margin-top: 25%;
          width: 100%;
          height: 0;
          margin-bottom: 107%; }
        .about .aboutValueDfn .image-collage .row .div2 {
          width: 100%; }
        .about .aboutValueDfn .image-collage .row .div3 {
          width: 90%;
          float: right; }
          @media (max-width: 992px) {
            .about .aboutValueDfn .image-collage .row .div3 {
              margin-bottom: 2rem; } }
          @media (max-width: 576px) {
            .about .aboutValueDfn .image-collage .row .div3 {
              margin-bottom: 0; } }
        .about .aboutValueDfn .image-collage .row .pic1 {
          width: 100%; }
        .about .aboutValueDfn .image-collage .row .pic2 {
          width: 100%; }
        .about .aboutValueDfn .image-collage .row .pic3 {
          width: 100%; }
    .about .aboutValueDfn .value-section {
      flex: 40% 1; }
      .about .aboutValueDfn .value-section p {
        margin: 40% 1.5rem 0rem; }
        @media (max-width: 992px) {
          .about .aboutValueDfn .value-section p {
            text-align: center;
            margin-top: 1rem; } }
  .about .aboutValueCtg {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    width: 80%;
    margin: 0 auto;
    padding: 3rem; }
    @media (max-width: 768px) {
      .about .aboutValueCtg {
        padding: 2rem;
        width: 100%; } }
    .about .aboutValueCtg ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: right;
      align-items: center; }
      @media (max-width: 576px) {
        .about .aboutValueCtg ul {
          flex-direction: column; } }
    .about .aboutValueCtg li {
      margin: 10px 25px;
      width: 15rem; }
    .about .aboutValueCtg .title {
      display: block;
      text-transform: uppercase;
      color: #2c4c68;
      font-weight: bold;
      text-align: right; }
      @media (max-width: 576px) {
        .about .aboutValueCtg .title {
          text-align: center; } }
    .about .aboutValueCtg li:nth-child(2) {
      width: 30rem; }
      @media (max-width: 576px) {
        .about .aboutValueCtg li:nth-child(2) {
          width: 100%; } }
  .about h1.ourValues_sm {
    font-size: 3.125em;
    line-height: 50px;
    color: #2b8ec8;
    margin: 2rem 0 0 4.5rem;
    display: none; }
    @media (max-width: 768px) {
      .about h1.ourValues_sm {
        display: block;
        width: 300px;
        margin: 0rem auto 2rem; } }
    @media (max-width: 576px) {
      .about h1.ourValues_sm {
        font-size: 2.5em;
        width: 230px;
        margin: 0rem auto 2rem; } }
    .about h1.ourValues_sm img {
      margin-top: 0.8rem; }
  .about h1.ourValues_lg {
    font-size: 3rem;
    line-height: 50px;
    color: #2b8ec8;
    margin: 1rem auto;
    width: 5em; }
    @media (max-width: 576px) {
      .about h1.ourValues_lg {
        font-size: 2.3rem;
        margin: 0;
        width: 100%;
        line-height: 47px; } }
    .about h1.ourValues_lg img {
      margin-top: 0.3rem; }
      @media (max-width: 576px) {
        .about h1.ourValues_lg img {
          margin-top: 0;
          width: 75%; } }

.about {
  font-size: 1.25rem;
  line-height: 50px;
  background: #f1f5f8;
  padding-bottom: 5rem; }
  .about .aboutMission {
    background: #2b8ec8;
    color: #fff; }
    .about .aboutMission h1 {
      font-weight: 600;
      margin: 0;
      text-align: center;
      padding: 5.000rem 0 3rem;
      font-size: 3.125em;
      line-height: 50px; }
      @media (max-width: 768px) {
        .about .aboutMission h1 {
          font-size: 2.5rem;
          padding: 3rem 0; } }
    .about .aboutMission p {
      -webkit-column-count: 2;
              column-count: 2;
      -webkit-column-rule-style: solid;
              column-rule-style: solid;
      grid-column-gap: 100px;
      -webkit-column-gap: 100px;
              column-gap: 100px;
      -webkit-column-rule-width: 1px;
              column-rule-width: 1px;
      padding-bottom: 9rem; }
      .about .aboutMission p .emphasis {
        font-weight: bold;
        display: block; }
        @media (max-width: 768px) {
          .about .aboutMission p .emphasis {
            text-align: center;
            font-size: larger; } }
      @media (max-width: 768px) {
        .about .aboutMission p {
          -webkit-column-count: 1;
                  column-count: 1; } }
  .about .separator {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAV8AAAABCAYAAAB5Rq2yAAAAI0lEQVQ4jWM8dOjQfwbyASMROkfNHzV/1PxR80fNRwYMDAwAN1QRR0Uq4IQAAAAASUVORK5CYII=) no-repeat;
    width: 351px;
    height: 5px;
    margin: 2em auto; }
  .about .aboutValueDfn {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 992px) {
      .about .aboutValueDfn {
        flex-direction: column; } }
    .about .aboutValueDfn .image-collage {
      flex: 45% 1;
      position: relative;
      margin-top: -5em;
      margin-bottom: 5rem; }
      .about .aboutValueDfn .image-collage .row {
        display: flex; }
        .about .aboutValueDfn .image-collage .row .col1 {
          width: 40%;
          padding: 0 10px 0 0; }
        .about .aboutValueDfn .image-collage .row .col2 {
          width: 60%;
          text-align: center;
          padding: 0 10px 0 0; }
        .about .aboutValueDfn .image-collage .row .div1 {
          margin-top: 25%;
          width: 100%;
          height: 0;
          margin-bottom: 107%; }
        .about .aboutValueDfn .image-collage .row .div2 {
          width: 100%; }
        .about .aboutValueDfn .image-collage .row .div3 {
          width: 90%;
          float: right; }
          @media (max-width: 992px) {
            .about .aboutValueDfn .image-collage .row .div3 {
              margin-bottom: 2rem; } }
          @media (max-width: 576px) {
            .about .aboutValueDfn .image-collage .row .div3 {
              margin-bottom: 0; } }
        .about .aboutValueDfn .image-collage .row .pic1 {
          width: 100%; }
        .about .aboutValueDfn .image-collage .row .pic2 {
          width: 100%; }
        .about .aboutValueDfn .image-collage .row .pic3 {
          width: 100%; }
    .about .aboutValueDfn .value-section {
      flex: 40% 1; }
      .about .aboutValueDfn .value-section p {
        margin: 40% 1.5rem 0rem; }
        @media (max-width: 992px) {
          .about .aboutValueDfn .value-section p {
            text-align: center;
            margin-top: 1rem; } }
  .about .aboutValueCtg {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    width: 80%;
    margin: 0 auto;
    padding: 3rem; }
    @media (max-width: 768px) {
      .about .aboutValueCtg {
        padding: 2rem;
        width: 100%; } }
    .about .aboutValueCtg ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: right;
      align-items: center; }
      @media (max-width: 576px) {
        .about .aboutValueCtg ul {
          flex-direction: column; } }
    .about .aboutValueCtg li {
      margin: 10px 25px;
      width: 15rem; }
    .about .aboutValueCtg .title {
      display: block;
      text-transform: uppercase;
      color: #2c4c68;
      font-weight: bold;
      text-align: right; }
      @media (max-width: 576px) {
        .about .aboutValueCtg .title {
          text-align: center; } }
    .about .aboutValueCtg li:nth-child(2) {
      width: 30rem; }
      @media (max-width: 576px) {
        .about .aboutValueCtg li:nth-child(2) {
          width: 100%; } }
  .about h1.ourValues_sm {
    font-size: 3.125em;
    line-height: 50px;
    color: #2b8ec8;
    margin: 2rem 0 0 4.5rem;
    display: none; }
    @media (max-width: 768px) {
      .about h1.ourValues_sm {
        display: block;
        width: 300px;
        margin: 0rem auto 2rem; } }
    @media (max-width: 576px) {
      .about h1.ourValues_sm {
        font-size: 2.5em;
        width: 230px;
        margin: 0rem auto 2rem; } }
    .about h1.ourValues_sm img {
      margin-top: 0.8rem; }
  .about h1.ourValues_lg {
    font-size: 3rem;
    line-height: 50px;
    color: #2b8ec8;
    margin: 1rem auto;
    width: 5em; }
    @media (max-width: 576px) {
      .about h1.ourValues_lg {
        font-size: 2.3rem;
        margin: 0;
        width: 100%;
        line-height: 47px; } }
    .about h1.ourValues_lg img {
      margin-top: 0.3rem; }
      @media (max-width: 576px) {
        .about h1.ourValues_lg img {
          margin-top: 0;
          width: 75%; } }

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  color: #FFF;
  font-size: 1.25rem; }
  .footer-top {
    width: 100%;
    background: #2b8ec8; }
    .footer-top .wrapper {
      background: #2b8ec8;
      padding: 3rem 6.25rem;
      display: flex;
      justify-content: space-between; }
      .footer-top .wrapper ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .footer-top .wrapper ul a {
          text-decoration: none;
          color: #fff; }
      @media (max-width: 992px) {
        .footer-top .wrapper {
          padding: 2rem 3rem 0 3rem; } }
      @media (max-width: 576px) {
        .footer-top .wrapper {
          background: #2b8ec8;
          padding: 3rem 6.25rem;
          display: flex;
          justify-content: space-between;
          justify-content: left;
          flex-direction: column;
          padding: 3rem; }
          .footer-top .wrapper ul {
            list-style: none;
            margin: 0;
            padding: 0; }
            .footer-top .wrapper ul a {
              text-decoration: none;
              color: #fff; } }
      .footer-top .wrapper ul {
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 1rem;
        margin-right: 0;
        padding: 0; }
        @media (max-width: 576px) {
          .footer-top .wrapper ul {
            margin-left: 0; } }
        .footer-top .wrapper ul.social {
          display: flex;
          margin-left: 0;
          padding-left: 0; }
          .footer-top .wrapper ul.social li {
            margin-right: 2.500em; }
        .footer-top .wrapper ul li {
          margin-bottom: 2.5rem; }
          @media (max-width: 576px) {
            .footer-top .wrapper ul li {
              margin-bottom: 1.5rem; } }
  .footer-bottom {
    background: #192d40;
    width: 100%;
    font-size: 0.8rem;
    text-transform: uppercase; }
    @media (max-width: 576px) {
      .footer-bottom {
        font-size: 1rem; } }
    .footer-bottom .wrapper {
      background: #192d40;
      padding: 3rem 6.25rem;
      display: flex;
      justify-content: space-between; }
      .footer-bottom .wrapper ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .footer-bottom .wrapper ul a {
          text-decoration: none;
          color: #fff; }
      @media (max-width: 992px) {
        .footer-bottom .wrapper {
          padding: 3rem; } }
      @media (max-width: 576px) {
        .footer-bottom .wrapper {
          justify-content: normal;
          flex-direction: column;
          padding: 2rem 3rem; } }
      .footer-bottom .wrapper ul {
        margin: 0; }
        .footer-bottom .wrapper ul.privacy {
          width: 18rem; }
        .footer-bottom .wrapper ul li {
          display: inline;
          margin-right: 6.250rem; }
          @media (max-width: 576px) {
            .footer-bottom .wrapper ul li {
              display: block;
              margin-right: 0;
              margin-bottom: 10px; } }
          .footer-bottom .wrapper ul li a {
            text-decoration: none;
            color: #fff; }
  .footer-content h1 {
    margin-bottom: 2.0rem; }
    @media (max-width: 992px) {
      .footer-content h1 {
        font-size: 1.8em; } }
    @media (max-width: 576px) {
      .footer-content h1 {
        margin-bottom: 1rem;
        font-size: 1.8em; } }
  .footer-content p {
    margin-bottom: 6.0rem; }
    @media (max-width: 992px) {
      .footer-content p {
        margin-bottom: 3.0rem; } }
    @media (max-width: 576px) {
      .footer-content p {
        margin-bottom: 3rem; } }
  .footer-contact {
    width: 18rem; }
    @media (max-width: 992px) {
      .footer-contact {
        margin-left: 1rem; } }
    @media (max-width: 576px) {
      .footer-contact {
        width: 100%;
        margin-left: 0rem; } }
    .footer-contact h1 {
      margin-bottom: 2.0rem; }
      @media (max-width: 992px) {
        .footer-contact h1 {
          font-size: 1.8em; } }
      @media (max-width: 576px) {
        .footer-contact h1 {
          margin-bottom: 1rem;
          font-size: 1.8em; } }

.wrapper {
  max-width: 82rem;
  margin: 0 auto;
  padding: 0 6.25rem; }
  @media (max-width: 768px) {
    .wrapper {
      padding: 0 2rem; } }
  @media (max-width: 576px) {
    .wrapper {
      padding: 0 2rem; } }

.text {
  padding: 1rem 3rem; }

body {
  margin: 0;
  background: #FFF;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  color: #2d4b68;
  height: 100%; }
  @media (max-width: 576px) {
    body {
      -webkit-hyphens: none;
              hyphens: none; } }

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0; }

p {
  margin: 0; }

* {
  box-sizing: inherit; }

#container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 38em;
  background: #f1f5f8; }
  @media (max-width: 576px) {
    #container {
      padding-bottom: 66rem; } }
  @media (max-width: 400px) {
    #container {
      padding-bottom: 70rem; } }
  @media (max-width: 300px) {
    #container {
      padding-bottom: 100rem; } }

#content {
  margin-top: 8.750rem; }

