@import "../../styles/media";

$jumbotron-mark-bg: #192d40; //#063252;
$wrapper-padding: 6.25rem;
$emphasis-text1: #2b8ec8;
$mission-bg: $emphasis-text1;
$mission-line: #375571;
$span-right-emphasis: #98c7f3;
$mission-services-h1:  3.125em;
$mission-services-h1-small: 2.0em;

#container {
  background: #ffffff;
}
.jumbotron {
  background: rgb(202,202,202);
  background: #1a3b4a;
  //background: linear-gradient(90deg, rgba(202,202,202,1) 0%, rgba(26,59,74,1) 44%);
}

div.jumbotronBG {
  background-repeat: no-repeat;
  padding-top: 150px;
  background-size: contain;
  background-position: right top;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;



  @include rwd(1129) {
    background-position: left top;
    background-size: cover;

    padding-top: 0;

  }

  @include lg {
    height: 712px;
  }
}
.jumbotronContent {
  padding-top: 8rem;
  padding-bottom: 8rem;
  display: flex;
  align-content: space-between;
  @include md {
    padding-top: 15rem;
    padding-bottom: 8rem;
    flex-direction: column-reverse;
    justify-content: center;
    height: inherit;
  }

  @include sm {
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: column-reverse;
    justify-content: center;
  }



  @include rwd(1129) {
    padding-top: 8rem;
    padding-bottom: 2rem;
  }

}

div.text1 {

  @include md {
    margin-top: 2rem;
  }
}
h1.text1 {
  font-size: 4.875rem;
  font-weight: bold;
  color: white;

  @include rwd(1200) {
    font-size: 3.5rem;
  }

  @include rwd(1129) {
    font-size: 3.0em;
  }

  @include lg {
    //Font-size: 2.4em
    font-size: 2.4em;
  }

  @include md {
    font-size: 2.0rem;
  }

}
h1.text1 mark {
  background: $jumbotron-mark-bg;
  color: #fff;
  padding: 0 15px 10px 0rem;
  box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.39);

}


span.space {
  display: inline-block;
  width: 4rem;
  height: 3rem;
}
span.emphasis-text1 {
  color: $emphasis-text1;
}
.text2 {
  p {
    font-size: 30px;
    font-weight: bold;
    color: white;
    margin-left: 1.0em;
    @include rwd(1129) {
      font-size: 1.8em;
    }


    @include lg {
      //Font-size: 1.4em
      font-size: 1.4em;
    }

    @include md {
      font-size: 1.5rem;
      margin-left: 0;
    }


  }

  span {
    display: inline-block;
    text-decoration: underline;
    text-decoration-color: #2b8ec8;
    clear: right;
  }

}

a.contact-btn {
  display: block;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.8rem;
  padding:2rem 0;
  float: right;
  position: relative;
  top: 0px;
  margin-right: 5rem;
  background: $emphasis-text1;
  min-width: 220px;
  text-align: center;
  box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.39);
  @include lg {
    //Font-size: 1.4em | padding: 1.0em 0 | min-width: 180px |
    font-size: 1.4em;
    padding: 1.0em 0;
    min-width: 180px;
  }

  @include md {
    font-size: 1.5rem;
    min-width: 85px;
    padding: 1.5rem 0.5rem;
    width: 185px;
  }



}

.mission {
  background: $mission-bg;
  color: #fff;
  font-size: 1.25rem;
  padding: 2.0em 0 0 0;
  max-height: 700px;
  border-top: 1px solid #fff;
  div.wrapper {

    display: flex;
    .left {
      line-height: 40px;
      h1 {
        font-size: $mission-services-h1;
        line-height: 50px;

        span {
          text-decoration: underline;
          text-decoration-color: $mission-line;
        }
      }

      p{
        margin: 30px 30px 30px 0;

        .emphasis {
          font-weight: bold;
        }
      }

      p:nth-child(3){
        margin-top: 20px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      font-size: 1.563em;
      font-weight: bold;
      text-align: center;
      p{
        position: relative;
        top:7px;
        .emphasis{
          color: $span-right-emphasis;
        }
      }

      img {
        width: 500px;
        box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.39);
      }
    }
  }


  @include rwd(1120) {
    max-height: none;
    padding-bottom: 2rem;
    div.wrapper {
      padding: 0 2rem;
      align-items: center;
      justify-content: center;

      .left {
        h1 {
          font-size: 2.5em;
        }
      }
      .right {
        img {
          width: 400px;
        }
      }
    }
  }

  @include rwd(940) {
    padding: 2em 0 0 0;
    div.wrapper {
      padding: 0 2rem;
      flex-direction: column;
      .left {
        p {
          margin: 30px 30px 0 0;
        }
      }
      .right {
          position: relative;
          top: 50px;
        font-size: 2.3rem;
      }
    }
  }
  @include md {
    max-height: none;
    padding: 2.0em 0;
    div.wrapper {
      .left h1{
        font-size: $mission-services-h1-small;
        line-height: 38px;
      }
      .right {
        display: none;
      }
      .left p {
        margin: 30px 0;
      }
    }
  }
}

.services {
  .wrapper{
    padding: 5.0em 0 3.0em;
    h1 {
      font-size: $mission-services-h1;
      line-height: 50px;
      color: $emphasis-text1;
      text-align: center;
      margin-bottom: 30px;
    }
    .accordionWrapperStyle {
      background: transparent;
      border-bottom: 1px solid $emphasis-text1;
      padding: 40px 20px;
    }
    .accordionWrapperStyleLast {
      background: transparent;
      padding: 40px 20px;
    }
    .content {
      display: flex;
      margin: 0 auto;
      align-content: space-between;
      justify-content: center;
      .left{
        margin: 20px;

        svg.gear_wheels {
          width: 400px;
        }
      }
      .right{
        //padding: 20px;
        width: 450px;
        //border: 1px solid greenyellow;

        .labelStyle {
          font-size: 22px;
          text-transform: uppercase;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
  @include  lg {
    .wrapper .content .left{
      svg.gear_wheels {
        width: 350px;
      }
    }
    .right{
      margin-right: 1.5rem;
    }
  }
  @include md {
    .wrapper {
      padding: 2.0em 3.0em;


      h1 {
        font-size: $mission-services-h1-small;
        line-height: 50px;
        color: $emphasis-text1;
        text-align: left;
        margin-bottom: 0;
      }
      .content {
        display: flex;
        margin: 0;
        flex-direction: column;

        .left{
          display: none;
        }
        .right{
          width: 100%;

          .labelStyle {
            font-size: 1.25em;
            text-transform: uppercase;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }

      .accordionWrapperStyle {
        background: transparent;
        border-bottom: 1px solid $emphasis-text1;
        padding: 40px 0px;
      }
      .accordionWrapperStyleLast {
        background: transparent;
        padding: 40px 0px;
      }
    }




  }
}
