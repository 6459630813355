@import 'utils';
@import "./media";

$font-stack: 'Source Sans Pro', sans-serif;
$body-bg-color: #FFF;
$body-text-color: #2d4b68;
$jumbotron-mark-bg: #063252;
$h1: 2.5rem; //40px
$footer-height: 38em;
$footer-height-small: 66rem;
$footer-height-extra-small: 70rem;
$wrapper-padding: 0 6.25rem;
$wrapper-padding-sm: 0 2rem;
$wrapper-padding-md: 0 2rem;
$container-bg: #f1f5f8;
.wrapper{
  max-width: 82rem;
  margin: 0 auto;
  padding: $wrapper-padding;

  @include md {
    padding: $wrapper-padding-md;
  }

  @include sm{
    padding: $wrapper-padding-sm;
  }

}

.text {
  padding: 1rem 3rem;
}
body {
  margin: 0;
  background: $body-bg-color;
  box-sizing: border-box;
  font:{
    family: $font-stack;
    weight: 300;
  }
  color: $body-text-color;
  height: 100%;

  @include sm {
    hyphens: none;
  }
}
h1{
  font:{
    size: $h1;
    weight: 600;
  }
  margin: 0;

}

p {
  margin: 0;
}

* {
  box-sizing: inherit;
}

#container{
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: $footer-height; //preserves footer by keeping it at the bottom w/o having a gap
  background: $container-bg;
  @include sm {
    padding-bottom: $footer-height-small;
  }

  @include rwd(400) {
    padding-bottom: $footer-height-extra-small;
  }

  @include rwd(300) {
    padding-bottom: 100rem;
  }
}

#topper {

}

#content {
  margin-top: 8.750rem;
}


