@import "../../styles/media";

$servContainer-bg: #f1f5f8;
$title-color: #2b8ec8;
.servicesContainer{
  background: $servContainer-bg;
  padding: 0rem 0 10rem;

  .wrapper {

    @include rwd(500){
      padding: 1rem;
    }
  }
  .columns-span-2 {
    display: flex;
  }
  .content {
    // background: #fff;
    border-radius: 5px;

    .h2 {
      font-size: 50px;
      color: $title-color;
      padding: 0;
      margin: 0;

      @include md {
        display: none;
      }
    }
    .h4 {
      font-size: 24px;
      color: $title-color;
      text-transform: uppercase;
      padding: 0;
      margin: 0.5rem;
    }

    .right {
      background: #FFFFFF;
      border-top: 3px solid $title-color;
      font-size: 1.25rem;
      line-height: 50px;

    }
    .tab-list {
      padding: 1rem 0 2rem 0;
      margin: 0;
    }

    .tab-list-item {
      background: #2b8ec8;
      text-align: center;
      color: white;
      height: 60px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-size: 20px;
      cursor: pointer;
    }

    .tab-list-active {
      background-color: #c7e3f5;
      color: #4c94be; //#2b8ec8;
      font-weight: bold;
    }

    .carousel {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 60px 0 20px;

      button {
        margin: 0 10px;
        width: 50px;
        height: 60px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        outline: none;
        color: #2b8ec8;
        background: #c7e3f5; //#b2d1d5;// #c4c4c4;
      }
    }

  }
}
